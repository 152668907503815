//import logo from "./logo.svg";
//import "./App.css";
import React from "react";
import Stopwatch from "./components/Stopwatch";
import Info from "./components/Info";
import Settings from "./components/Settings";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import useWindowDimensions from "./hooks/useWindowDimensions";
import { BsGear, BsInfoCircle, BsHouseDoor } from "react-icons/bs";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  IndexRoute,
  browserHistory,
  Routes,
} from "react-router-dom";

function App() {
  document.body.ontouchmove = (e) => {
    e.preventDefault();
    console.log("thing i copied");
    return false;
  };
  let targetElement = null;
  const { height, width } = useWindowDimensions();
  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false);
    }, 1);
    console.log("page to reload");
  }
  React.useEffect(() => {
    targetElement = document.querySelector("#bigboi");
    disableBodyScroll(targetElement);
  }, []);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "fixed",
      }}
      id="bigboi"
    >
      <Router>
        <div className="App" style={{ overflow: "hidden" }}>
          <div
            style={{
              position: "absolute",
              zIndex: 2,
              display: "flex",
              flex: 1,
              flexDirection: "row",
              color: "white",
              alignItems: "flex-end",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>
              <Link to="/" refresh="true" onClick={refreshPage}>
                <BsHouseDoor
                  style={{
                    color: "white",
                    width: "40",
                    height: "40",
                    paddingTop: 10,
                    paddingLeft: width > 500 ? 10 : 4,
                    paddingRight: width > 500 ? 10 : 4,
                  }}
                />
              </Link>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div>
                <Link to="/info" refresh="true" onClick={refreshPage}>
                  <BsInfoCircle
                    data-tut="four"
                    style={{
                      color: "white",
                      width: "40",
                      height: "40",
                      paddingTop: 10,
                      paddingLeft: width > 500 ? 10 : 4,
                      paddingRight: width > 500 ? 10 : 4,
                    }}
                  />
                </Link>
              </div>
              <div>
                <Link to="/settings" refresh="true" onClick={refreshPage}>
                  <BsGear
                    data-tut="three"
                    style={{
                      color: "white",
                      width: "40",
                      height: "40",
                      paddingTop: 10,
                      paddingLeft: width > 500 ? 10 : 4,
                      paddingRight: width > 500 ? 10 : 4,
                    }}
                  />
                </Link>
              </div>
            </div>
          </div>
          <Routes>
            <Route exact path="/" element={<Stopwatch />} />
            <Route path="/info" element={<Info />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
