import React from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
/*import {View, Text, StyleSheet, ImageBackground} from 'react-native';
import Video from 'react-native-video';
import FastImage from 'react-native-fast-image';*/

const Info = () => {
  const { height, width } = useWindowDimensions();
  return (
    <div style={{ backgroundColor: "white", height: "100%" }}>
      {/*<View style={styles.header}>*/}
      <div
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        colors={["#2e9445", "#73a335"]}
        style={styles.linearGradient}
      >
        <h1
          style={{ ...styles.text3, ...styles.defaultOverride }}
          className="font"
        >
          Info
        </h1>
      </div>
      <div
        style={{
          backgroundColor: "white",
          width: width > 500 ? "40%" : "75%",
          margin: "auto",
          textAlign: "center",
        }}
        className="font"
      >
        <p style={styles.text2}>🎵 Music by CLOVR Records</p>
        <a href="https://clovrrecords.com/" style={styles.text2}>
          ➡Visit our website!
        </a>
        <br />
        <a href="https://clovrrecords.com/#spotify" style={styles.text2}>
          ➡Check out our Spotify playlists!
        </a>
        <br />
        <a href="https://discord.gg/3szPBEuUr3" style={styles.text2}>
          ➡Join our Discord!
        </a>
        <p style={styles.text2}>💻 Developed by George Wang, Zachary Wiencko</p>
        <p style={styles.text2}>🎨 Animation by ginkgo whale</p>
        <p>
          Lofi Bear is a productivity tool created by CLOVR Records designed to
          make tedious work sessions comfortable and enjoyable through calming
          lo-fi music, soothing rain sounds, a friendly animation, and a
          stopwatch that allows time for breaks. <br />
          <br />
          If you would like to support the project, please share with your
          friends! Also consider{" "}
          <a href="https://discord.gg/3szPBEuUr3">joining our Discord</a> to
          submit music or just to meet some friends!
        </p>
      </div>
      {/*<div style={styles.buttonFlex}>
        <button
          //title={buttonStates[buttonState].bottomButtonText}
          style={styles.button2}
        >
          Back
        </button>
  </div>*/}
    </div>
  );
};

const styles = {
  defaultOverride: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
  text3: {
    //color: "#fff",
    color: "white",
    fontSize: 20,
    textAlign: "center",
    //position: "absolute",
    top: 0,
    width: "100%",
    background: "linear-gradient(#1ba15a, #12a30d)",
    lineHeight: "3em",
    fontWeight: "normal",
    //fontFamily: "Poppins-Deez",
  },
  text2: {
    color: "black",
  },
};

export default Info;
