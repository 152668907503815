import React from "react";
import koala from "../koala.mp4";
import useWindowDimensions from "../hooks/useWindowDimensions";
/*import {View, Text, StyleSheet, ImageBackground} from 'react-native';
import Video from 'react-native-video';
import FastImage from 'react-native-fast-image';*/

const Animation = () => {
  const { height, width } = useWindowDimensions();
  React.useEffect(() => {}, []);
  return (
    <div style={{ width: "100%" }}>
      {/*<img
        style={{
          width: "100%",
          //height: 422,
          alignItems: "center",
          position: "relative",
          //marginLeft: -70,
        }}
        src={require("../koala.gif")}
      />*/}
      {/* width < 500
              ? "391%"
              : width < 700
              ? "250%"
              : width < 1000
              ? "170%"
              : width < 1200
              ? "145%"
              : "100%", */}
      <video
        src={koala}
        poster={require("../koala.gif")}
        playsInline
        autoPlay
        muted
        loop
        style={{
          overflow: "hidden",
          width:
            width < 500
              ? "391%"
              : width < 700
              ? "250%"
              : width < 1000
              ? "170%"
              : width < 1200
              ? "145%"
              : "100%",
          height: "100%",
          objectFit: "cover",
          transform:
            width < 500 ? "translate(-300px, 0px)" : "translate(0px,0px)",
        }}
      />
      {/*<img
        src={require("../koala.gif")}
        style={{
          overflow: "hidden",
          width:
            width < 500
              ? "391%"
              : width < 700
              ? "250%"
              : width < 1000
              ? "170%"
              : width < 1200
              ? "145%"
              : "100%",
          height: "100%",
          objectFit: "cover",
          transform:
            width < 500 ? "translate(-300px, 0px)" : "translate(0px,0px)",
        }}
      />*/}
    </div>
  );
};

const styles = {};

export default Animation;
