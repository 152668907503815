import React from "react";
import Select from "react-select";
import Animation from "../components/Animation";
import useWindowDimensions from "../hooks/useWindowDimensions";
/*import {View, Text, StyleSheet, ImageBackground} from 'react-native';
import Video from 'react-native-video';
import FastImage from 'react-native-fast-image';*/

const Settings = () => {
  const { height, width } = useWindowDimensions();
  const options = [
    { value: "600", label: "10 minutes" },
    { value: "900", label: "15 minutes" },
    { value: "1200", label: "20 minutes" },
    { value: "1800", label: "30 minutes" },
    { value: "2400", label: "40 minutes" },
    { value: "3000", label: "50 minutes" },
    { value: "3600", label: "1 hour" },
  ];
  const options2 = [
    { value: "300", label: "5 minutes" },
    { value: "600", label: "10 minutes" },
    { value: "900", label: "15 minutes" },
    { value: "1200", label: "20 minutes" },
    { value: "1800", label: "30 minutes" },
  ];
  const options3 = [
    { value: "0", label: "No music" },
    { value: "25", label: "25%" },
    { value: "50", label: "50%" },
    { value: "75", label: "75%" },
    { value: "100", label: "100%" },
  ];
  const options4 = [
    { value: "0", label: "No rain" },
    { value: "5", label: "25%" },
    { value: "10", label: "50%" },
    { value: "15", label: "75%" },
    { value: "20", label: "100%" },
  ];

  let [allSettings, setAllSettings] = React.useState(
    JSON.parse(localStorage.getItem("@settings"))
      ? JSON.parse(localStorage.getItem("@settings"))
      : {
          minStudy: 1800,
          maxBreak: 600,
          musicVolume: 20,
          rainVolume: 10,
        }
  );
  let [selectedMinStudy, setSelectedMinStudy] = React.useState("5");
  let [selectedMaxBreak, setSelectedMaxBreak] = React.useState("5");
  let [selectedMusicVolume, setSelectedMusicVolume] = React.useState("100");
  let [selectedRainVolume, setSelectedRainVolume] = React.useState("20");

  React.useEffect(() => {
    /*if (localStorage.getItem("@settings") != null) {
      setAllSettings(JSON.parse(localStorage.getItem("@settings")));
    }*/
    /*if (allSettings.minStudy == null) {
      setAllSettings({ ...allSettings, ...{ minStudy: 10 } });
    }
    if (allSettings.maxBreak == null) {
      setAllSettings({ ...allSettings, ...{ maxBreak: 10 } });
    }
    if (allSettings.musicVolume == null) {
      setAllSettings({ ...allSettings, ...{ musicVolume: 100 } });
    }
    if (allSettings.rainVolume == null) {
      setAllSettings({ ...allSettings, ...{ rainVolume: 10 } });
    }
    setSelectedMinStudy(allSettings.minStudy);
    setSelectedMaxBreak(allSettings.maxBreak);
    setSelectedMusicVolume(allSettings.musicVolume);
    console.log(allSettings);*/
  }, []);

  React.useEffect(() => {
    /*if (!allSettings.minStudy) {
      allSettings.minStudy = 10;
    }
    if (!allSettings.maxBreak) {
      allSettings.maxBreak = 5;
    }
    if (!allSettings.musicVolume) {
      allSettings.musicVolume = 100;
    }
    if (!allSettings.rainVolume) {
      allSettings.rainVolume = 10;
    }*/
    localStorage.setItem("@settings", JSON.stringify(allSettings));
  });

  return (
    <div style={{ width: "100%", height: "100%", position: "fixed" }}>
      <div
        style={{
          //backgroundColor: "white",
          height: "100%",
          //position: "absolute",
        }}
      >
        {/*<View style={styles.header}>*/}
        <div
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
          colors={["#2e9445", "#73a335"]}
          style={styles.linearGradient}
        >
          <h1
            style={{ ...styles.text3, ...styles.defaultOverride }}
            className="font"
          >
            Settings
          </h1>
          {/*<p style={styles.text}>Settings</p>*/}
        </div>
        <div
          style={{
            //backgroundColor: "transparent",
            //position: "absolute",
            paddingTop: 150,
            paddingBottom: 300,
            width: width > 500 ? "25%" : "50%",
            textAlign: "center",
            margin: "auto",
          }}
        >
          <p style={styles.text} className="font">
            Study Session Length
          </p>
          <Select
            isSearchable={false}
            value={selectedMinStudy}
            onChange={(values) => {
              setSelectedMinStudy(values);
              allSettings.minStudy = values.value;
            }}
            options={options}
          />
          <p style={styles.text} className="font">
            Break Length
          </p>
          <Select
            isSearchable={false}
            value={selectedMaxBreak}
            onChange={(values) => {
              setSelectedMaxBreak(values);
              allSettings.maxBreak = values.value;
            }}
            options={options2}
          />
          {/*<p style={styles.text} className="font">
            Music Volume
          </p>
          <Select
            isSearchable={false}
            value={selectedMusicVolume}
            onChange={(values) => {
              setSelectedMusicVolume(values);
              allSettings.musicVolume = values.value;
            }}
            options={options3}
          />
          <p style={styles.text} className="font">
            Rain Volume
          </p>
          <Select
            isSearchable={false}
            value={selectedRainVolume}
            onChange={(values) => {
              setSelectedRainVolume(values);
              allSettings.rainVolume = values.value;
            }}
            options={options4}
          />*/}
        </div>
        {/*<div style={styles.buttonFlex}>
          <button
            //title={buttonStates[buttonState].bottomButtonText}
            style={styles.button2}
          >
            Back
          </button>
          </div>*/}
        <div style={{ position: "absolute", top: 30, zIndex: -1 }}>
          <Animation style={{ position: "absolute", top: -200 }} />
        </div>
      </div>
    </div>
  );
};

const styles = {
  defaultOverride: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
  text3: {
    //color: "#fff",
    color: "white",
    fontSize: 20,
    textAlign: "center",
    //position: "absolute",
    top: 0,
    width: "100%",
    background: "linear-gradient(#1ba15a, #12a30d)",
    lineHeight: "3em",
    fontWeight: "normal",
    //fontFamily: "Poppins-Deez",
  },
  text: {
    //paddingTop: 20,
    marginTop: 15,
    marginBottom: 0,
    color: "white",
    backgroundColor: "green",
    borderRadius: 10,
  },
};

export default Settings;
