import React from "react";
import { useStopwatch } from "react-timer-hook";
import Animation from "../components/Animation";
import Sound from "react-sound";
import "./Stopwatch.css";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import Tour from "reactour";

const track0 = {
  url: "./songs/goson-chasing-dreams.mp3",
  title: "Chasing Dreams",
  artist: "Goson",
  artwork: require("../public/songs/goson-chasing-dreams.jpg"),
};

const track1 = {
  url: "./songs/devon-rea-side-streets.mp3",
  title: "Side Streets",
  artist: "Devon Rea",
  artwork: require("../public/songs/goson-chasing-dreams.jpg"),
};

const track2 = {
  url: "./songs/steezy-prime-luv-pug-crush.mp3",
  title: "Crush",
  artist: "steezy prime, luv pug",
  artwork: require("../public/songs/goson-chasing-dreams.jpg"),
};

const track3 = {
  url: "./songs/vibe-valley-marc-brave-sunshine-walk.mp3",
  title: "Sunshine Walk",
  artist: "Vibe Valley, Marc Brave",
  artwork: require("../public/songs/goson-chasing-dreams.jpg"),
};

const track4 = {
  url: "./songs/cmj-seafoam.mp3",
  title: "Seafoam",
  artist: "CMJ",
  artwork: require("../public/songs/goson-chasing-dreams.jpg"),
};

const track5 = {
  url: "./songs/calicronk-zephyr.mp3",
  title: "Zephyr",
  artist: "CaliCronk",
  artwork: require("../public/songs/goson-chasing-dreams.jpg"),
};

const track6 = {
  url: "./songs/basic-beats-radiate.mp3",
  title: "Radiate",
  artist: "Basic Beats",
  artwork: require("../public/songs/goson-chasing-dreams.jpg"),
};

const track7 = {
  url: "./songs/kbeats-gerardo-millan-forbidden-noises.mp3",
  title: "Forbidden Noises",
  artist: "kBeats, Gerardo Millan",
  artwork: require("../public/songs/goson-chasing-dreams.jpg"),
};

const track8 = {
  url: "./songs/lofi-luke-dewho-redbone.mp3",
  title: "Redbone",
  artist: "Lo-Fi Luke, dewho",
  artwork: require("../public/songs/lofi-luke-dewho-redbone.jpg"),
};

const track9 = {
  url: "./songs/tyler.l-feelith-night-sky.mp3",
  title: "Night Sky",
  artist: "Tyler.l, feelith",
  artwork: require("../public/songs/tyler.l-feelith-night-sky.jpg"),
};

const track10 = {
  url: "./songs/tyler.l-feelith-loneliness.mp3",
  title: "Loneliness",
  artist: "Tyler.l, feelith",
  artwork: require("../public/songs/tyler.l-feelith-night-sky.jpg"),
};

const track11 = {
  url: "./songs/tyler.l-feelith-northern-star.mp3",
  title: "Northern Star",
  artist: "Tyler.l, feelith",
  artwork: require("../public/songs/tyler.l-feelith-night-sky.jpg"),
};

const track12 = {
  url: "./songs/tyler.l-feelith-peace-of-soul.mp3",
  title: "Peace of Soul",
  artist: "Tyler.l, feelith",
  artwork: require("../public/songs/tyler.l-feelith-night-sky.jpg"),
};

const track13 = {
  url: "./songs/guidolain-beats-rainbow.mp3",
  title: "Rainbow",
  artist: "Guidolain Beats",
  artwork: require("../public/songs/guidolain-beats-rainbow.jpg"),
};

const track14 = {
  url: "./songs/guidolain-beats-umbrella.mp3",
  title: "Umbrella",
  artist: "Guidolain Beats",
  artwork: require("../public/songs/guidolain-beats-rainbow.jpg"),
};

const track15 = {
  url: "./songs/guidolain-beats-mystic-fire.mp3",
  title: "Mystic Fire",
  artist: "Guidolain Beats",
  artwork: require("../public/songs/guidolain-beats-rainbow.jpg"),
};

const track16 = {
  url: "./songs/guidolain-beats-rainy-call.mp3",
  title: "Rainy Call",
  artist: "Guidolain Beats",
  artwork: require("../public/songs/guidolain-beats-rainbow.jpg"),
};

const track17 = {
  url: "./songs/jav-cast-passed-by.mp3",
  title: "Passed By",
  artist: "Jav Cast",
  artwork: require("../public/songs/jav-cast-passed-by.jpg"),
};

const track18 = {
  url: "./songs/jav-cast-outer-worlds.mp3",
  title: "Outer Worlds",
  artist: "Jav Cast",
  artwork: require("../public/songs/jav-cast-passed-by.jpg"),
};

const track19 = {
  url: "./songs/renboz-chillpac-wonderland.mp3",
  title: "Wonderland",
  artist: "RenBoz, Chillpac",
  artwork: require("../public/songs/renboz-chillpac-wonderland.jpg"),
};

const track20 = {
  url: "./songs/renboz-chillpac-coffee-shop.mp3",
  title: "Coffee Shop",
  artist: "RenBoz, Chillpac",
  artwork: require("../public/songs/renboz-chillpac-wonderland.jpg"),
};

const track21 = {
  url: "./songs/gemp-cirrus.mp3",
  title: "Cirrus",
  artist: "Gemp",
  artwork: require("../public/songs/gemp-cirrus.jpg"),
};

const track22 = {
  url: "./songs/jean-kee-pinetree-campfire-stories.mp3",
  title: "Campfire Stores",
  artist: "Jean Kee, Pinetree",
  artwork: require("../public/songs/jean-kee-pinetree-campfire-stories.jpg"),
};

const track23 = {
  url: "./songs/enoki-soft-temptations.mp3",
  title: "Soft Temptations",
  artist: "Enoki",
  artwork: require("../public/songs/enoki-soft-temptations.jpg"),
};

const track24 = {
  url: "./songs/beyond-pluto-toasty.mp3",
  title: "Toasty",
  artist: "Beyond Pluto",
  artwork: require("../public/songs/beyond-pluto-toasty.jpg"),
};

const track25 = {
  url: "./songs/alhivi-slately-tears-come-from-the-heart.mp3",
  title: "alhivi, Slately",
  artist: "Tears Come From the Heart",
  artwork: require("../public/songs/alhivi-slately-tears-come-from-the-heart.jpg"),
};

const track26 = {
  url: "./songs/sheath-luv-pug-nightfall.mp3",
  title: "Sheath, luv pug",
  artist: "Nightfall",
  artwork: require("../public/songs/sheath-luv-pug-nightfall.jpg"),
};

const track27 = {
  url: "./songs/cloudsurfin-benytheproducer-floating.mp3",
  title: "Floating",
  artist: "Cloudsurfin, Benytheproducer",
  artwork: require("../public/songs/cloudsurfin-benytheproducer-floating.jpg"),
};

const track28 = {
  url: "./songs/aerow-converse.mp3",
  title: "Converse",
  artist: "Aerow",
  artwork: require("../public/songs/aerow-converse.jpg"),
};

const track29 = {
  url: "./songs/lofi-luke-tranquil-teddy-metro-dullness.mp3",
  title: "Metro Dullness",
  artist: "Lo-Fi Luke, Tranquil Teddy",
  artwork: require("../public/songs/lofi-luke-tranquil-teddy-metro-dullness.jpg"),
};

const track30 = {
  url: "./songs/dran-beat-station-jade.mp3",
  title: "Jade",
  artist: "Dran Beat Station",
  artwork: require("../public/songs/dran-beat-station-jade.jpg"),
};

const track31 = {
  url: "./songs/jav-cast-cruising.mp3",
  title: "Cruising",
  artist: "Jav Cast",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track32 = {
  url: "./songs/epigenesis-life-by-your-side.mp3",
  title: "Life By Your Side",
  artist: "Epigenesis",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track33 = {
  url: "./songs/goson-in-the-middle.mp3",
  title: "In the Middle",
  artist: "Goson",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track34 = {
  url: "./songs/lazy-vibes-summer-vibes.mp3",
  title: "Summer Vibes",
  artist: "Lazy Vibes",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track35 = {
  url: "./songs/hikari-feels-good.mp3",
  title: "Feels Good",
  artist: "Hikari",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track36 = {
  url: "./songs/lofi-luke-dewho-one-espresso-please.mp3",
  title: "One Espresso Please",
  artist: "Lo-Fi Luke, dewho",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track37 = {
  url: "./songs/devon-rea-moving-on.mp3",
  title: "Moving On",
  artist: "Devon Rea",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track38 = {
  url: "./songs/tophat-panda-hanabi.mp3",
  title: "Hanabi",
  artist: "Tophat Panda",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track39 = {
  url: "./songs/kid-kio-blueberry-beach.mp3",
  title: "Blueberry Beach",
  artist: "Kid Kio",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track40 = {
  url: "./songs/ol-wallace-afternoon-in-june.mp3",
  title: "Afternoon in June",
  artist: "Ol Wallace",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track41 = {
  url: "./songs/trell-daniels-last-call.mp3",
  title: "Last Call",
  artist: "Trell Daniels",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track42 = {
  url: "./songs/sineg-time-travel.mp3",
  title: "Time Travel",
  artist: "Sineg",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track43 = {
  url: "./songs/calicronk-my-front-porch.mp3",
  title: "My Front Porch",
  artist: "CaliCronk",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track44 = {
  url: "./songs/kalako-poolside.mp3",
  title: "Poolside",
  artist: "Kalako",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track45 = {
  url: "./songs/dewho-docs-inc.mp3",
  title: "Docs inc",
  artist: "dewho",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track46 = {
  url: "./songs/enoki-matcha.mp3",
  title: "Matcha",
  artist: "Enoki",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track47 = {
  url: "./songs/tranquil-teddy-paris-green.mp3",
  title: "Paris Green",
  artist: "Tranquil Teddy",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track48 = {
  url: "./songs/spoonbeats-congrats.mp3",
  title: "Congrats",
  artist: "SpoonBeats",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track49 = {
  url: "./songs/linearwave-calm.mp3",
  title: "Calm",
  artist: "Linearwave",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track50 = {
  url: "./songs/sxul-closer-to-you.mp3",
  title: "Closer to You",
  artist: "Sxul",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track51 = {
  url: "./songs/bcalm-banks-gerardo-millan-dear-friend.mp3",
  title: "Dear Friend",
  artist: "Bcalm, Banks, Gerardo Millan",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track52 = {
  url: "./songs/lofi-luke-keep-going.mp3",
  title: "Keep Going",
  artist: "Lo-Fi Luke",
  artwork: require("../public/songs/jav-cast-cruising.jpg"),
};

const track53 = {
  url: "./songs/goland-skyline.mp3",
  title: "Skyline",
  artist: "Goland",
  artwork: require("../public/songs/goland-skyline.jpg"),
};

const track54 = {
  url: "./songs/marc-brave-rick-nice-gimbap.mp3",
  title: "Gimbap",
  artist: "Marc Brave, Rick Nice",
  artwork: require("../public/songs/marc-brave-rick-nice-gimbap.jpg"),
};

const track55 = {
  url: "./songs/lofi-luke-sushi-spirited-away.mp3",
  title: "Spirited Away",
  artist: "Lo-Fi Luke, SUSHI",
  artwork: require("../public/songs/lofi-luke-sushi-spirited-away.jpg"),
};

const track56 = {
  url: "./songs/oddability-beat-fanatics-view-from-my-window.mp3",
  title: "Views From my Window",
  artist: "Oddability Beat fanatics",
  artwork: require("../public/songs/oddability-beat-fanatics-view-from-my-window.jpg"),
};

const track57 = {
  url: "./songs/rebecca-mardal-trying-not-to-fight-myself.mp3",
  title: "Trying Not to Fight Myself",
  artist: "Rebecca Mardal",
  artwork: require("../public/songs/rebecca-mardal-trying-not-to-fight-myself.jpg"),
};

const track58 = {
  url: "./songs/enoki-let-me-hope.mp3",
  title: "Let Me Hope",
  artist: "Enoki",
  artwork: require("../public/songs/enoki-let-me-hope.jpg"),
};

const track59 = {
  url: "./songs/enoki-rays.mp3",
  title: "Rays",
  artist: "Enoki",
  artwork: require("../public/songs/enoki-let-me-hope.jpg"),
};

const track60 = {
  url: "./songs/goland-daydrinks.mp3",
  title: "Daydrinks",
  artist: "Goland",
  artwork: require("../public/songs/goland-daydrinks.jpg"),
};

const track61 = {
  url: "./songs/kioko-early-morning.mp3",
  title: "Early Morning",
  artist: "Kioko",
  artwork: require("../public/songs/kioko-early-morning.jpg"),
};

const track62 = {
  url: "./songs/honey-t-mellow-grounds-life.mp3",
  title: "Mellow Grounds Life",
  artist: "Honey T",
  artwork: require("../public/songs/honey-t-mellow-grounds-life.jpg"),
};

const track63 = {
  url: "./songs/immerson-stars.mp3",
  title: "Stars",
  artist: "Immerson",
  artwork: require("../public/songs/immerson-stars.jpg"),
};

const track64 = {
  url: "./songs/lofi-luke-goland-stay-with-me.mp3",
  title: "Stay With Me",
  artist: "Lo-Fi Luke, Goland",
  artwork: require("../public/songs/lofi-luke-goland-stay-with-me.jpg"),
};

const track65 = {
  url: "./songs/mondo-loops-snoozegod-on-the-way-home.mp3",
  title: "On the Way Home",
  artist: "Mondo Loops, Snoozegod",
  artwork: require("../public/songs/mondo-loops-snoozegod-on-the-way-home.jpg"),
};

const track66 = {
  url: "./songs/idkwat-dreaming.mp3",
  title: "Dreaming",
  artist: "idkwat",
  artwork: require("../public/songs/idkwat-dreaming.jpg"),
};

const track67 = {
  url: "./songs/idkwat-miles-away.mp3",
  title: "Miles Away",
  artist: "idkwat",
  artwork: require("../public/songs/idkwat-dreaming.jpg"),
};

const track68 = {
  url: "./songs/lofi-sweden-fields.mp3",
  title: "Fields",
  artist: "Lofi Sweden",
  artwork: require("../public/songs/lofi-sweden-fields.jpg"),
};

const track69 = {
  url: "./songs/lofi-luke-marc-brave-staring.mp3",
  title: "Staring",
  artist: "Lo-Fi Luke, Marc Brave",
  artwork: require("../public/songs/lofi-luke-marc-brave-staring.jpg"),
};

const track70 = {
  url: "./songs/lofi-luke-marc-brave-daylight.mp3",
  title: "Daylight",
  artist: "Lo-Fi Luke, Marc Brave",
  artwork: require("../public/songs/lofi-luke-marc-brave-daylight.jpg"),
};

const track71 = {
  url: "./songs/jose-bautista-dear-heart.mp3",
  title: "Dear Heart",
  artist: "Jose Bautista",
  artwork: require("../public/songs/jose-bautista-dear-heart.jpg"),
};

const track72 = {
  url: "./songs/the-afterglow-serenity.mp3",
  title: "Serenity",
  artist: "The Afterglow",
  artwork: require("../public/songs/the-afterglow-serenity.jpg"),
};

const track73 = {
  url: "./songs/enoki-siesta.mp3",
  title: "Siesta",
  artist: "Enoki",
  artwork: require("../public/songs/enoki-siesta.jpg"),
};

const track74 = {
  url: "./songs/atp-storytelling.mp3",
  title: "Storytelling",
  artist: "A.T.P.",
  artwork: require("../public/songs/atp-storytelling.jpg"),
};

const track75 = {
  url: "./songs/goson-natasha-ghosh-crisp-air.mp3",
  title: "Crisp Air",
  artist: "Goson, Natasha Ghosh",
  artwork: require("../public/songs/enoki-siesta.jpg"),
};

const track76 = {
  url: "./songs/tranquil-teddy-lofi-luke-snowman.mp3",
  title: "Snowman",
  artist: "Lo-Fi Luke, Tranquil Teddy",
  artwork: require("../public/songs/enoki-siesta.jpg"),
};

const track77 = {
  url: "./songs/jav-cast-gingerbread.mp3",
  title: "Gingerbread",
  artist: "Jav Cast",
  artwork: require("../public/songs/enoki-siesta.jpg"),
};

const track78 = {
  url: "./songs/cmj-somehow.mp3",
  title: "Somehow",
  artist: "CMJ",
  artwork: require("../public/songs/enoki-siesta.jpg"),
};

const track79 = {
  url: "./songs/tranquil-teddy-lofi-luke-purpose.mp3",
  title: "Purpose",
  artist: "Tranquil Teddy, Lo-Fi Luke",
  artwork: require("../public/songs/tranquil-teddy-lofi-luke-purpose.jpg"),
};

const track80 = {
  url: "./songs/lofi-luke-not-enough.mp3",
  title: "Not Enough",
  artist: "Lo-Fi Luke",
  artwork: require("../public/songs/lofi-luke-not-enough.jpg"),
};

const track81 = {
  url: "./songs/jean-kee-pinetree-saturday-sunset.mp3",
  title: "Saturday Sunset",
  artist: "Jean Kee, Pinetree",
  artwork: require("../public/songs/jean-kee-pinetree-saturday-sunset.jpg"),
};

const track82 = {
  url: "./songs/elsei-trust-me.mp3",
  title: "Trust Me",
  artist: "Elsei",
  artwork: require("../public/songs/elsei-trust-me.jpg"),
};

const track83 = {
  url: "./songs/dusty-keys-it-was-warmer.mp3",
  title: "It Was Warmer",
  artist: "Dusty Keys",
  artwork: require("../public/songs/dusty-keys-it-was-warmer.jpg"),
};

const track84 = {
  url: "./songs/dusty-keys-cold-drinks.mp3",
  title: "Cold Drinks",
  artist: "Dusty Keys",
  artwork: require("../public/songs/dusty-keys-it-was-warmer.jpg"),
};

const track85 = {
  url: "./songs/dusty-keys-chillpac-old-habits.mp3",
  title: "Old Habits",
  artist: "Dusty Keys, Chillpac",
  artwork: require("../public/songs/dusty-keys-it-was-warmer.jpg"),
};

const track86 = {
  url: "./songs/bt-themood.mp3",
  title: "The Mood",
  artist: "BT",
  artwork: require("../public/songs/bt-themood.jpg"),
};

const track87 = {
  url: "./songs/vibe-valley-homesickness.mp3",
  title: "Homesickness",
  artist: "Vibe Valley",
  artwork: require("../public/songs/vibe-valley-homesickness.jpg"),
};

const track88 = {
  url: "./songs/goland-pulse.mp3",
  title: "Pulse",
  artist: "Goland",
  artwork: require("../public/songs/vibe-valley-homesickness.jpg"),
};

const track89 = {
  url: "./songs/vibe-valley-bubble-tea.mp3",
  title: "Bubble Tea",
  artist: "Vibe Valley",
  artwork: require("../public/songs/vibe-valley-bubble-tea.jpg"),
};

const track90 = {
  url: "./songs/skyswimming-rdbeats-aurora.mp3",
  title: "Aurora",
  artist: "skyswimming, RdBeats",
  artwork: require("../public/songs/skyswimming-rdbeats-aurora.jpg"),
};

const track91 = {
  url: "./songs/aerow-long-road-home.mp3",
  title: "Long Road Home",
  artist: "Aerow",
  artwork: require("../public/songs/aerow-long-road-home.jpg"),
};

const track92 = {
  url: "./songs/sushi-waiting-for-you.mp3",
  title: "Waiting for You",
  artist: "SUSHI",
  artwork: require("../public/songs/sushi-waiting-for-you.jpg"),
};

const track93 = {
  url: "./songs/lofi-luke-marc-brave-city-lights.mp3",
  title: "City Lights",
  artist: "Lo-Fi Luke",
  artwork: require("../public/songs/lofi-luke-marc-brave-city-lights.jpg"),
};

const track94 = {
  url: "./songs/hikari-no-wonder-why.mp3",
  title: "No Wonder Why",
  artist: "Hikari",
  artwork: require("../public/songs/hikari-no-wonder-why.jpg"),
};

const track95 = {
  url: "./songs/cdm-promenade.mp3",
  title: "Promenade",
  artist: "CDM",
  artwork: require("../public/songs/cdm-promenade.jpg"),
};

const track96 = {
  url: "./songs/tranquil-teddy-lofi-luke-missouri-night.mp3",
  title: "Missouri Night",
  artist: "Tranquil Teddy, Lo-Fi Luke",
  artwork: require("../public/songs/tranquil-teddy-lofi-luke-missouri-night.jpg"),
};

const track97 = {
  url: "./songs/of-these-woods-starlit-cabin.mp3",
  title: "Starlit Cabin",
  artist: "Of These Woods",
  artwork: require("../public/songs/of-these-woods-starlit-cabin.jpg"),
};

const track98 = {
  url: "./songs/tranquil-teddy-venice.mp3",
  title: "Venice",
  artist: "Tranquil Teddy",
  artwork: require("../public/songs/tranquil-teddy-venice.jpg"),
};

const track99 = {
  url: "./songs/tophat-panda-afternoon-sun.mp3",
  title: "Afternoon Sun",
  artist: "Tophat Panda",
  artwork: require("../public/songs/tophat-panda-afternoon-sun.jpg"),
};

const track100 = {
  url: "./songs/lofi-luke-goson-ride.mp3",
  title: "Ride",
  artist: "Lo-Fi Luke, Goson",
  artwork: require("../public/songs/lofi-luke-goson-ride.jpg"),
};

const tracks = {
  track0: track0,
  track1: track1,
  track2: track2,
  track3: track3,
  track4: track4,
  track5: track5,
  track6: track6,
  track7: track7,
  track8: track8,
  track9: track9,
  track10: track10,
  track11: track11,
  track12: track12,
  track13: track13,
  track14: track14,
  track15: track15,
  track16: track16,
  track17: track17,
  track18: track18,
  track19: track19,
  track20: track20,
  track21: track21,
  track22: track22,
  track23: track23,
  track24: track24,
  track25: track25,
  track26: track26,
  track27: track27,
  track28: track28,
  track29: track29,
  track30: track30,
  track31: track31,
  track32: track32,
  track33: track33,
  track34: track34,
  track35: track35,
  track36: track36,
  track37: track37,
  track38: track38,
  track39: track39,
  track40: track40,
  track41: track41,
  track42: track42,
  track43: track43,
  track44: track44,
  track45: track45,
  track46: track46,
  track47: track47,
  track48: track48,
  track49: track49,
  track50: track50,
  track51: track51,
  track52: track52,
  track53: track53,
  track54: track54,
  track55: track55,
  track56: track56,
  track57: track57,
  track58: track58,
  track59: track59,
  track60: track60,
  track61: track61,
  track62: track62,
  track63: track63,
  track64: track64,
  track65: track65,
  track66: track66,
  track67: track67,
  track68: track68,
  track69: track69,
  track70: track70,
  track71: track71,
  track72: track72,
  track73: track73,
  track74: track74,
  track75: track75,
  track76: track76,
  track77: track77,
  track78: track78,
  track79: track79,
  track80: track80,
  track81: track81,
  track82: track82,
  track83: track83,
  track84: track84,
  track85: track85,
  track86: track86,
  track87: track87,
  track88: track88,
  track89: track89,
  track90: track90,
  track91: track91,
  track92: track92,
  track93: track93,
  track94: track94,
  track95: track95,
  track96: track96,
  track97: track97,
  track98: track98,
  track99: track99,
  track100: track100,
};

const Stopwatch = () => {
  const steps = [
    {
      selector: '[data-tut="none"]',
      content: ({ goTo }) => (
        <div>
          <h2>Welcome to Lofi Bear!</h2>
          <p color="#e5e5e5">
            Lofi Bear is a study/music app developed by CLOVR Records! <br />
            <br />
            You must study for 30 minute sessions with 10 minute breaks in
            between! Press the green button to learn how to use it!
          </p>
          <button
            style={{
              color: "white",
              border: "2px solid black",
              borderRadius: "5px",
              //background: "none",
              backgroundColor: "blue",
              padding: ".3em .7em",
              fontSize: ".8em",
              display: "block",
              cursor: "pointer",
              margin: "1em auto",
            }}
            onClick={() => {
              setTourOpen(false);
            }}
          >
            Skip Tour
          </button>
          <button
            style={{
              color: "white",
              border: "2px solid black",
              borderRadius: "5px",
              //background: "none",
              backgroundColor: "green",
              padding: ".3em .7em",
              fontSize: ".8em",
              display: "block",
              cursor: "pointer",
              margin: "1em auto",
            }}
            onClick={() => {
              goTo(1);
            }}
          >
            Take Tour
          </button>
        </div>
      ),
    },
    {
      selector: '[data-tut="one"]',
      content:
        "Press this button to start the timer and the music. After 30 minutes another button will appear allowing you to take a break!",
    },
    {
      selector: '[data-tut="two"]',
      content:
        "Use these controls to change volume or mute music while the music is playing!",
    },
    {
      selector: '[data-tut="three"]',
      content:
        "Click here to change the length of study session or break! (The current session will end.)",
    },
    {
      selector: '[data-tut="four"]',
      content:
        "Click here to learn more about CLOVR Records and check out our Spotify playlists and Discord!",
    },
    {
      selector: '[data-tut="none"]',
      content: () => (
        <div>
          <p color="#e5e5e5">
            Happy studying! Here's to a bright future! Sincerely, The CLOVR
            Team.
          </p>
          <button
            style={{
              color: "white",
              border: "2px solid black",
              borderRadius: "5px",
              //background: "none",
              backgroundColor: "green",
              padding: ".3em .7em",
              fontSize: "1.1em",
              display: "block",
              cursor: "pointer",
              margin: "1em auto",
            }}
            onClick={() => {
              setTourOpen(false);
            }}
          >
            Close
          </button>
        </div>
      ),
    },
    // ...
  ];

  const { seconds, minutes, hours, days, isRunning, start, pause, reset } =
    useStopwatch({ autoStart: false });

  const { height, width } = useWindowDimensions();

  const [isOnBreak, setOnBreak] = React.useState(false);
  const [isStudying, setStudying] = React.useState(false);
  const [studyFinished, setStudyFinished] = React.useState(false);
  const [breakOver, setBreakOver] = React.useState(false);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isMuted, setIsMuted] = React.useState(false);
  const [muteText, setMuteText] = React.useState("MUTE");

  // state 0 = stopped, 1 = studying, 2 = break
  const [buttonState, setButtonState] = React.useState(0);
  const [topText, setTopText] = React.useState("");
  const [bottomText, setBottomText] = React.useState("");

  //current song
  const [currSong, setCurrSong] = React.useState(track99);
  const [minStudy, setMinStudy] = React.useState("");

  let [nowPlayingText, setNowPlayingText] = React.useState("");
  let [nowPlayingArt, setNowPlayingArt] = React.useState(
    require("../public/empty.png")
  );

  const [tourOpen, setTourOpen] = React.useState(true);

  const buttonStates = [
    {
      // start
      topButtonText: "🎮 Take a Break",
      bottomButtonText: "✏️ Start Studying",
    },
    {
      // studying
      topButtonText: "🎮 Take a Break",
      bottomButtonText: "End Study Session",
    },
    {
      // break
      topButtonText: "✏️ Continue Studying",
      bottomButtonText: "End Study Session",
    },
  ];

  let minStudyTime = {
    hours: 0,
    minutes: 30,
    seconds: 0,
  };

  let maxBreakTime = {
    hours: 0,
    minutes: 10,
    seconds: 0,
  };

  let [settings, setSettings] = React.useState({
    minStudy: 1800,
    maxBreak: 600,
    musicVolume: 20,
    rainVolume: 10,
  });

  var minStudyTimeHoursText = "";
  var minStudyTimeMinutesText = "";
  var minStudyTimeSecondsText = "";
  var maxBreakTimeHoursText = "";
  var maxBreakTimeMinutesText = "";
  var maxBreakTimeSecondsText = "";

  const handleTopStates = () => {
    switch (buttonState) {
      case 0: // start, cant press
        break;

      case 1: // studying
        setButtonState(2); // set to break
        reset();
        setTopText("You've been on break for:");
        setBottomText(
          `Continue studying after ${maxBreakTimeHoursText}${maxBreakTimeMinutesText}${maxBreakTimeSecondsText}.`
        );
        break;

      case 2: // on break
        setButtonState(1); // set to studying
        reset();
        setStudyFinished(false);
        setBreakOver(false);
        setTopText("You've been studying for:");
        setBottomText(
          `You can take a break after ${minStudyTimeHoursText}${minStudyTimeMinutesText}${minStudyTimeSecondsText}!`
        );
        break;
    }
  };

  const handleBottomStates = async () => {
    try {
      switch (buttonState) {
        case 0: // start
          console.log("start studying case");
          setNowPlayingArt(currSong.artwork);
          setNowPlayingText(
            `Now Playing: ${currSong.artist} - ${currSong.title}`
          );
          setIsPlaying(true);
          newSong();
          setTopText("You've been studying for:");
          setBottomText(
            `You can take a break after ${minStudyTimeHoursText}${minStudyTimeMinutesText}${minStudyTimeSecondsText}!`
          );
          setButtonState(1);
          reset();
          break;

        case 1: // studying
          console.log("stop studying case");
          console.log("stopped studying from studying state");
          setIsPlaying(false);
          setButtonState(0);
        case 2: // break
          setIsPlaying(false);
          setButtonState(0);
          reset();
          pause();
          setTopText("");
          setBottomText("");
          setStudyFinished(false);
          setBreakOver(false);
          break;
      }
    } catch (e) {
      console.log("error in buttons");
    }
  };

  /*const setup = async () => {
    await TrackPlayer.setup({});
  };*/

  React.useEffect(() => {
    //localStorage.clear();
    let sett = localStorage.getItem("@settings");
    if (sett != {} && sett) {
      setSettings(JSON.parse(localStorage.getItem("@settings")));
    }
    if (settings.minStudy == null) {
      settings.minStudy = 1800;
    }
    if (settings.maxBreak == null) {
      settings.maxBreak = 600;
    }
    if (settings.musicVolume == null) {
      settings.musicVolume = 20;
    }
    if (settings.rainVolume == null) {
      settings.rainVolume = 10;
    }
    //let sett = localStorage.getItem("@settings");
    /*if (sett) {
      setSettings(JSON.parse(localStorage.getItem("@settings")));
    }*/
    /*if (sett.minStudy) {
      setSettings({ ...settings, minStudy: sett.minStudy });
    }
    if (sett.maxBreak) {
      setSettings({ ...settings, maxBreak: sett.maxBreak });
    }
    if (sett.musicVolume) {
      setSettings({ ...settings, musicVolume: sett.musicVolume });
    }
    if (sett.rainVolume) {
      setSettings({ ...settings, rainVolume: sett.rainVolume });
    }*/
  }, []);

  React.useEffect(() => {
    minStudyTime.hours = Math.floor(settings.minStudy / 3600);
    minStudyTime.minutes = Math.floor(
      (settings.minStudy - 3600 * minStudyTime.hours) / 60
    );
    minStudyTime.seconds =
      settings.minStudy - 3600 * minStudyTime.hours - 60 * minStudyTime.minutes;

    maxBreakTime.hours = Math.floor(settings.maxBreak / 3600);
    maxBreakTime.minutes = Math.floor(
      (settings.maxBreak - 3600 * maxBreakTime.hours) / 60
    );
    maxBreakTime.seconds =
      settings.maxBreak - 3600 * maxBreakTime.hours - 60 * maxBreakTime.minutes;

    if (minStudyTime.hours == 0) {
      minStudyTimeHoursText = "";
    } else {
      minStudyTimeHoursText = minStudyTime.hours.toString() + " hours";
    }

    if (minStudyTime.minutes == 0) {
      minStudyTimeMinutesText = "";
    } else {
      minStudyTimeMinutesText = minStudyTime.minutes.toString() + " minutes";
    }

    if (minStudyTime.seconds == 0) {
      minStudyTimeSecondsText = "";
    } else {
      minStudyTimeSecondsText = minStudyTime.seconds.toString() + " seconds";
    }

    if (maxBreakTime.hours == 0) {
      maxBreakTimeHoursText = "";
    } else {
      maxBreakTimeHoursText = maxBreakTime.hours.toString() + " hours";
    }

    if (maxBreakTime.minutes == 0) {
      maxBreakTimeMinutesText = "";
    } else {
      maxBreakTimeMinutesText = maxBreakTime.minutes.toString() + " minutes";
    }

    if (maxBreakTime.seconds == 0) {
      maxBreakTimeSecondsText = "";
    } else {
      maxBreakTimeSecondsText = maxBreakTime.seconds.toString() + " seconds";
    }
  });

  React.useEffect(() => {
    if (
      buttonState == 1 &&
      seconds >= minStudyTime.seconds &&
      minutes >= minStudyTime.minutes &&
      hours >= minStudyTime.hours
    ) {
      setStudyFinished(true);
      //console.log('finished workinging');
      setBottomText("You can take a break now.");
    }
    if (
      buttonState == 2 &&
      seconds >= maxBreakTime.seconds &&
      minutes >= maxBreakTime.minutes &&
      hours >= maxBreakTime.hours
    ) {
      setBreakOver(true);
      //console.log('break over');
      setBottomText("Please continue working.");
    }
    setNowPlayingText(`Now Playing: ${currSong.artist} - ${currSong.title}`);
    setNowPlayingArt(currSong.artwork);
  });

  const newSong = () => {
    try {
      let randomNumber = Math.floor(Math.random() * 100);
      let randomTrackStr = "track" + randomNumber.toString();
      setCurrSong(tracks[randomTrackStr]);
      //if (isPlaying) {
      //setNowPlayingText(`Now Playing: ${currSong.artist} - ${currSong.title}`);
      //setNowPlayingArt(currSong.artwork);
      //}
    } catch (e) {
      console.log("bruh");
    }
  };

  return (
    <div
      style={{ backgroundColor: "white", height: "100%", overflow: "hidden" }}
      className="font"
    >
      <div>
        <Sound
          url={process.env.PUBLIC_URL + currSong.url}
          volume={
            isMuted
              ? 0
              : settings.musicVolume !== null
              ? parseInt(settings.musicVolume)
              : 20
          }
          playStatus={!isPlaying ? Sound.status.STOPPED : Sound.status.PLAYING}
          onFinishedPlaying={() => {
            newSong();
            /*setNowPlayingText(
              `Now Playing: ${currSong.artist} - ${currSong.title}`
            );
            setNowPlayingArt(currSong.artwork);*/
          }}
        />
        {/*<Sound
          url={process.env.PUBLIC_URL + "./rain.mp3"}
          volume={
            isMuted
              ? 0
              : settings.rainVolume !== null
              ? parseInt(settings.rainVolume)
              : 10
          }
          loop={true}
          playStatus={!isPlaying ? Sound.status.STOPPED : Sound.status.PLAYING}
        />*/}
        <div
          id="bigboi"
          style={{
            position: "relative",
          }}
        >
          <Animation />
          <h1
            style={{ ...styles.text3, ...styles.defaultOverride }}
            className="font"
          >
            Lofi Bear
          </h1>

          <div
            style={{ ...styles.timerFlexContainer, ...styles.defaultOverride }}
          >
            <div style={styles.timeContainer}>
              <p style={{ ...styles.text, ...styles.defaultOverride }}>
                {("0" + hours).slice(-2)}
                {"\n"}
                <span style={styles.smallText}>{"Hours"}</span>
              </p>
            </div>

            <div style={styles.timeContainer}>
              <p style={{ ...styles.text, ...styles.defaultOverride }}>
                {("0" + minutes).slice(-2)}
                {"\n"}
                <span style={styles.smallText}>{"Minutes"}</span>
              </p>
            </div>

            <div style={styles.timeContainer}>
              <p style={{ ...styles.text, ...styles.defaultOverride }}>
                {("0" + seconds).slice(-2)}
                {"\n"}
                <span style={styles.smallText}>{"Seconds"}</span>
              </p>
            </div>
          </div>

          <div>
            <p
              style={{
                //opacity: 1,
                opacity: isPlaying ? 1 : 0,
                position: "absolute",
                top: 60,
                left: 80,
                color: "white",
              }}
              className="font"
            >
              {nowPlayingText}
            </p>
            <img
              style={{
                opacity: 1,
                //opacity: isPlaying ? 1 : 0,
                width: 60,
                height: 60,
                position: "absolute",
                top: 70,
                left: 10,
                //marginLeft: 8,
                //marginTop: 31,
                borderRadius: 5,
              }}
              src={nowPlayingArt}
              className="border"
              //resizeMode={FastImage.resizeMode.contain}
            />

            <button
              data-tut="two"
              style={{
                opacity: 1,
                //opacity: isPlaying ? 1 : 0,
                width: 60,
                height: 30,
                position: "absolute",
                top: 140,
                left: 10,
                fontSize: 11,
                textAlign: "center",
                //marginLeft: 8,
                //marginTop: 31,
                borderRadius: 5,
              }}
              className="font"
              onClick={() => {
                if (isMuted == false) {
                  setIsMuted(true);
                  setMuteText("UNMUTE");
                } else {
                  setIsMuted(false);
                  setMuteText("MUTE");
                }
              }}
            >
              {muteText}
            </button>

            <Slider
              style={{
                opacity: 1,
                //opacity: isPlaying ? 1 : 0,
                width: 60,
                //height: 30,
                position: "absolute",
                top: 170,
                left: 11,
                fontSize: 11,
                textAlign: "center",
                //marginLeft: 8,
                //marginTop: 31,
                //orderRadius: 5,
              }}
              defaultValue={settings.musicVolume}
              onChange={(value) => {
                var temp = settings;
                temp.musicVolume = value;
                setSettings(temp);
              }}
            />
          </div>

          <p style={styles.text4}> {topText} </p>

          {/*<div
            style={{
              width: "100%",
              position: "absolute",
              bottom: 326,
              zIndex: 3,
            }}
          >
          <div style={{ position: "relative" }}>*/}
          <p
            style={{
              color: "white",
              fontSize: 16,
              textAlign: "center",
              position: "absolute",
              width: "100%",
              //margin: "auto",
              //left: document.getElementById("bigboi").offsetWidth / 2,
              //margin: "0 auto",
              //marginTop: 378,
              //width: "100%",
              //fontFamily: "Poppins-Light"，
              //backgroundColor: "grey",
              bottom: 326,
              //bottom: 356,
              zIndex: 3,
            }}
          >
            {" "}
            {bottomText}{" "}
          </p>
          {/*</div>
          </div>*/}

          <div
            style={{
              position: "absolute",
              //bottom: width > 500 ? 250 : 190,
              bottom: width > 500 ? 250 : 220,
              //backgroundColor: "white",
              borderWidth: 3,
              borderColor: "white",
              //display: "flex",
              flexDirection: "column",
              textAlign: "center",
              //alignItems: 'center',
              width: "100%",
              //height: windowHeight - (Platform.OS === "ios" ? 540 : 510),
              justifyContent: "space-evenly",
              resizeMode: "contain",
            }}
          >
            {/* break button / continue studying*/}
            {studyFinished && (
              <button
                //title={buttonStates[buttonState].topButtonText}
                style={styles.button1}
                color={buttonState == 1 ? "green" : ""}
                disabled={!studyFinished}
                onClick={() => handleTopStates()}
              >
                {buttonStates[buttonState].topButtonText}
              </button>
            )}

            {/* start study session / end study session */}
            <button
              //title={buttonStates[buttonState].bottomButtonText}
              data-tut="one"
              style={styles.button2}
              onClick={() => handleBottomStates()}
            >
              {buttonStates[buttonState].bottomButtonText}
            </button>
          </div>
        </div>
      </div>
      <Tour
        accentColor="green"
        steps={steps}
        isOpen={tourOpen}
        onRequestClose={() => {
          setTourOpen(false);
        }}
      />
    </div>
  );
};

const styles = {
  defaultOverride: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
  timerFlexContainer: {
    display: "flex",
    position: "absolute",
    flexDirection: "row",
    //top: 0,
    bottom: 400,
    height: 75,
    width: "100%",
    justifyContent: "center",
    lineHeight: "20px",
    alignItems: "center",
    //margin: 'auto',
  },
  timeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "center",
    backgroundColor: "rgba(221, 213, 211, 0.75)",
    height: "100%",
    width: 75,
    borderRadius: 15,
    marginLeft: 20,
    marginRight: 20,
  },
  text: {
    fontSize: 38,
    //fontFamily: "Poppins-Regular",
    textAlign: "center",
    color: "green",
    position: "relative",
    //marginTop: 350,
    //marginLeft: 50,
  },
  textMin: {
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: "rgba(221, 213, 211, 0.75)",
    textAlign: "center",
    color: "green",
    fontSize: 38,
    position: "absolute",
    //marginTop: 350,
    //marginLeft: 160,
    //height: "13%",
    width: "18%",
    borderRadius: 15,
  },
  textSec: {
    backgroundColor: "rgba(221, 213, 211, 0.75)",
    textAlign: "center",
    color: "green",
    fontSize: 38,
    position: "absolute",
    //marginTop: 350,
    //marginLeft: 270,
    height: "13%",
    width: "18%",
    borderRadius: 15,
  },
  smallText: {
    backgroundColor: "transparent",
    color: "black",
    fontSize: 12,
    textAlign: "center",
    //fontFamily: 'Poppins-Regular',
  },
  text2: {
    color: "white",
    fontSize: 16,
    textAlign: "center",
    position: "absolute",
    bottom: 326,
    //left: {windowHeight}
    //margin: "0 auto",
    //marginTop: 378,
    //width: "100%",
    //fontFamily: "Poppins-Light"，
    zIndex: 3,
    backgroundColor: "grey",
  },
  text4: {
    color: "white",
    fontSize: 16,
    textAlign: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: 500,
    //bottom: 460,
    width: "100%",
    //fontFamily: "Poppins-Light",
  },
  nowPlaying: {
    /*color: "white",
    fontSize: 12,
    //textAlign: 'center',
    //justifyContent: 'center',
    position: "absolute",
    marginTop: 8,
    marginLeft: 8,
    width: "100%",
    fontFamily: "Poppins-Light",*/
  },
  text2green: {
    /*color: "#03fc1c",
    fontSize: 16,
    textAlign: "center",
    position: "absolute",
    marginTop: 378,
    width: "100%",
    fontFamily: "Poppins-Light",*/
  },
  text2red: {
    /*color: "red",
    fontSize: 16,
    textAlign: "center",
    position: "absolute",
    marginTop: 378,
    width: "100%",
    fontFamily: "Poppins-Light",*/
  },
  button1: {
    backgroundColor: "white",
    color: "#2a9345",
    padding: 14,
    borderColor: "#2a9345",
    borderWidth: 2,
    borderRadius: 10,
    width: "auto",
    marginLeft: 20,
    marginRight: 20,
    textAlign: "center",
    //fontFamily: "Poppins-Regular",
    fontSize: 18,
  },
  button2: {
    backgroundColor: "#2a9345",
    color: "white",
    padding: 14,
    borderColor: "#2a9345",
    borderWidth: 2,
    borderRadius: 10,
    width: "auto",
    marginLeft: 20,
    marginRight: 20,
    textAlign: "center",
    //fontFamily: "Poppins-Regular",
    fontSize: 18,
  },
  buttonFlex: {
    position: "absolute",
    bottom: 250,
    //backgroundColor: "white",
    borderWidth: 3,
    borderColor: "white",
    flexDirection: "column",
    textAlign: "center",
    //alignItems: 'center',
    width: "100%",
    //height: windowHeight - (Platform.OS === "ios" ? 540 : 510),
    justifyContent: "space-evenly",
    resizeMode: "contain",
  },
  container2: {
    /*alignItems: "center",*/
  },
  header: {
    /*height: 60,
    padding: 10,
    backgroundColor: "darkslateblue",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-end",*/
  },
  linearGradient: {
    /*height: 60,
    padding: 10,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",*/
  },
  text3: {
    //color: "#fff",
    color: "white",
    fontSize: 20,
    textAlign: "center",
    position: "absolute",
    top: 0,
    width: "100%",
    background: "linear-gradient(#1ba15a, #12a30d)",
    lineHeight: "3em",
    fontWeight: "normal",
    //fontFamily: "Poppins-Deez",
  },
};

export default Stopwatch;
